<template>
  <section>
    <div class="location" :style="`color:${landing.advanced.global.weather.font_color}`">{{ location }}</div>
    <!--        <div class="weather__description">{{ description }}</div>-->
    <div class="location time" v-if="showTime">
      <div class="time" :style="`color:${landing.advanced.global.weather.font_color}`">{{ date }} {{ time }}</div>
    </div>
  </section>
</template>

<script>
import moment from 'moment-timezone'
import {mapState} from "vuex";

export default {
  name: 'Weather',

  props: {
    location: {
      type: String,
      required: true
    },
    isHotel: Boolean
  },
  data() {
    return {
      time: '',
      date: '',
      show: false,
    }
  },
  computed: {
    ...mapState(['landing']),
    ...mapState(['hotel']),
    showTime:function (){
      if(this.isHotel){
        return this.hotel.advanced.global.show_time
      }else{
        return this.landing.advanced.global.show_time
      }
    }
  },
  created() {
    this.updateTime();
    setInterval(this.updateTime, 1000);
    if (this.isHotel && this.hotel.advanced.global.show_time) {
      this.show = true;
    }
    if (!this.isHotel && this.landing.advanced.global.show_time) {
      this.show = true;
    }

  },
  methods: {
    zeroPadding(num, digit) {
      let zero = '';
      for (let i = 0; i < digit; i++) {
        zero += '0';
      }
      return (zero + num).slice(-digit);
    },
    updateTime() {
      if (this.landing.advanced.global.timezone) {
        let date = moment().tz(this.landing.advanced.global.timezone.name);
        this.time = date.format('HH:mm');
        this.date = date.format('DD.MM.YYYY');
      } else {
        this.time = moment().format('HH:mm');
        this.date = moment().format('DD.MM.YYYY');
      }
    },
  }
}
</script>

<style scoped>
section {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.location {
  text-transform: uppercase;
  text-align: center;
}

.weather__description {
  text-transform: lowercase;
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
}

.weather__description:first-letter {
  text-transform: uppercase;
}

.weather__icon {
  width: 12em;
  padding-bottom: 9em;
}
</style>
