<template>
    <div  v-if="show">
        <div @click="toWeather" v-if="showDefault||showWeek||showLite" class="weather" :class="{weatherHotel:isHotel}" :style="`padding:${isHotel?'20px':'5px'};width:100%;margin:0;`">
            <main v-if="showDefault" :style="`border-radius:${landing.advanced.global.weather.border_radius}px;`">
                <Measurements
                        :cloudiness="cloudiness"
                        :windSpeed="windSpeed"
                        :humidity="humidity"
                ></Measurements>

                <Temperature
                        :value="weekTemp"
                        :high="temperatureHigh"
                        :low="temperatureLow"
                        :description="forecast.description"
                        :icon="weekIcon"
                        :iconWo="weekIconWo"
                        :type-icon="iconType"
                ></Temperature>

                <Weather
                        :is-hotel="isHotel"
                        :location="locationShow"
                ></Weather>
            </main>
            <div class="week"  v-if="showWeek" :style="`width:100%;border-radius:${landing.advanced.global.weather.border_radius}px;`">
                <div class="weather-panel">
                    <div class="row">
                        <div style="padding: 30px;width: 50%;">
                            <h2 style="display: flex;flex-direction: column;" :style="`color:${landing.advanced.global.weather.font_color}`">{{ locationShow }}<br><small>{{ todayFormat(weekDay) }}</small><small v-if="showTime">{{ time }}</small></h2>
                            <p class="h3" style="display: flex;align-items:center;" :style="`color:${landing.advanced.global.weather.font_color}`">
                                <img v-if="iconType === 0"  :src="`https://openweathermap.org/img/wn/${weekIcon}@4x.png`" alt="">
                                <i v-if="iconType === 1"  style="height: 30px;width: 30px;font-size:30px;margin-right: 10px;"  class="owf" :class="`owf-${weekIconWo}`"></i>
                                {{ weekMain }}</p>
                        </div>
                        <div class="text-center" style="padding: 30px;width: 50%;" :style="`color:${landing.advanced.global.weather.font_color}`">
                            <div class="h1 temperature">
                                <span>{{ kelvinToCer(weekTemp) }}°</span>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;padding: 15px;" :style="`color:${landing.advanced.global.weather.font_color}`">
                        <ul class="list-inline row forecast" style="padding-left: 0;">
                            <li style="width:16.6%;padding: 12px;" class="text-center" v-for="(day,dayIndex) in weekDays" :key="dayIndex">
                                <h5 class="h5">{{ convertDay(day.dt) }}</h5>
                                <p><img v-if="iconType === 0" :src="`https://openweathermap.org/img/wn/${day.weather[0].icon}@4x.png`" alt="">
                                    <i v-if="iconType === 1"  style="height: 30px;width: 30px;font-size:30px;"  class="owf" :class="`owf-${day.weather[0].id}`"></i><br>{{kelvinToCer(day.temp.day)}}°</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="lite"  v-if="showLite" style="width: 90%;">
                <div style="display: flex;flex-direction: column;align-items: flex-end;" :style="`color:${font_color};`">
                    <img style="height: 70px;width: 70px;" v-if="iconType === 0" :src="`https://openweathermap.org/img/wn/${weekIcon}@4x.png`" alt="">
                    <i :style="`color:${font_color};`" v-if="iconType === 1"  style="height: 40px;width: 40px;font-size:40px;"  class="owf" :class="`owf-${weekIconWo}`"></i>
                    <div>{{ weekMain }} {{ kelvinToCer(weekTemp) }}°</div>
                    <div><small>{{ todayFormat(weekDay) }}</small> <small v-if="showTime">{{ time }}</small></div>
                </div>
            </div>
        </div>
        <div class="simple" v-if="landing.advanced.global.weather.style === 'simple'" style="margin-top: 15px;">
            <div style="padding:5px;width: 100%;display: flex;">
                <div style="padding: 15px;border-radius: 5px;background:linear-gradient(#999,#eee);color:#fff;width:100%;display: flex;position:relative;justify-content: space-between;align-items: center;">
<!--                    <div style="font-size:30px;font-weight: 600;">-->
<!--                        <div>-->
<!--                            {{landing.advanced.global.city_id.name}}-->
<!--                        </div>-->
<!--                    </div>-->
                    <div style="position: absolute;top:-10px;left:0;">
                        <img :src="'/weather/'+'Cloudy_day'+'.png'" alt="">
                    </div>
                    <div></div>
                    <div style="display: flex;align-items: center;justify-content: flex-end;position: relative;">
                        <div style="font-size:30px;font-weight: 600;">
                            {{landing.advanced.global.weather.week.daily[0].temp.day.toString().slice(0,2)}}°
                        </div>
                        <div style="width: 1px;margin: 0 15px;height:40px;background-color: #fff;"></div>
                        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
                            <div>
                                {{landing.advanced.global.weather.week.daily[0].temp.min.toString().slice(0,2)}}° /
                                {{landing.advanced.global.weather.week.daily[0].temp.max.toString().slice(0,2)}}°
                            </div>
                            <div style="display: flex;align-items: center;justify-content: center;">                                <div>

                            <v-icon color="#fff">mdi-water</v-icon>
                            </div>
                                <div>
                                    {{landing.advanced.global.weather.week.daily[0].humidity}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Weather from './Weather'
import Temperature from './Temperature'
import Measurements from './Measurements'
import {mapState} from "vuex";
import moment from 'moment'
import '@/scss/css/owfont-regular.min.css'
export default {
    name: 'WeatherApp',
    props:{
      isHotel:Boolean
    },
    components: {
        Weather,
        Temperature,
        Measurements
    },
    computed:{
      ...mapState(['landing']),
      ...mapState(['hotel']),
      ...mapState('advanced',['location']),
      font_color:function(){
        if(this.isHotel){
          return this.hotel.advanced.global.weather.font_color
        }else{
          return this.landing.advanced.global.weather.font_color
        }
      },
      iconType:function(){
        if(this.isHotel){
          return this.hotel.advanced.global.weather_icon_type
        }else{
          return this.landing.advanced.global.weather_icon_type
        }
      },
      show:function(){
        if(this.isHotel){
          return this.hotel.advanced.global.show_weather && this.hotel.advanced.global.show_widget  && this.hotel.advanced.global.weather.style !== 'text'
        }else{
          return this.landing.advanced.global.show_weather && this.landing.advanced.global.show_widget && this.landing.advanced.global.weather.style !== 'text'
        }
      },
      showTime:function (){
        if(this.isHotel){
          return this.hotel.advanced.global.show_time
        }else{
          return this.landing.advanced.global.show_time
        }
      },
      showDefault:function (){
        if(this.isHotel){
          return this.hotel.advanced.global.weather.style === 'default'
        }else{
          return this.landing.advanced.global.weather.style === 'default'
        }
      },
      showWeek:function (){
        if(this.isHotel){
          return this.hotel.advanced.global.weather.style === 'week'
        }else{
          return this.landing.advanced.global.weather.style === 'week'
        }
      },
      showLite:function (){
        if(this.isHotel){
          return this.hotel.advanced.global.weather.style === 'lite'
        }else{
          return this.landing.advanced.global.weather.style === 'lite'
        }
      },
      weekDay:function (){
        if(this.isHotel){
          return this.hotel.advanced.global.weather.week.daily[0].dt
        }else{
          return this.landing.advanced.global.weather.week.daily[0].dt
        }
      },
      weekTemp:function (){
        if(this.isHotel){
          return this.hotel.advanced.global.weather.week.daily[0].temp.day
        }else{
          return this.landing.advanced.global.weather.week.daily[0].temp.day
        }
      },
      weekDays:function (){
        if(this.isHotel){
          return this.hotel.advanced.global.weather.week.daily.slice(1,7)
        }else{
          return this.landing.advanced.global.weather.week.daily.slice(1,7)
        }
      },
      weekMain:function (){
        if(this.isHotel){
          return this.hotel.advanced.global.weather.week.daily[0].weather[0].main
        }else{
          return this.landing.advanced.global.weather.week.daily[0].weather[0].main
        }
      },
      weekIcon:function (){
        if(this.isHotel){
          return this.hotel.advanced.global.weather.week.daily[0].weather[0].icon
        }else{
          return this.landing.advanced.global.weather.week.daily[0].weather[0].icon
        }
      },
      weekIconWo:function (){
        if(this.isHotel){
          return this.hotel.advanced.global.weather.week.daily[0].weather[0].id
        }else{
          return this.landing.advanced.global.weather.week.daily[0].weather[0].id
        }
      },
      locationShow:function(){
        if(this.isHotel){
          return this.hotel.advanced.global.show_locations;
        }else{
          return this.location;
        }
      },
      cloudiness:function(){
        if(this.isHotel){
          return this.hotel.advanced.global.weather.cloudiness;
        }else{
          return this.landing.advanced.global.weather.cloudiness;
        }
      },
      humidity:function(){
        if(this.isHotel){
          return this.hotel.advanced.global.weather.humidity;
        }else{
          return this.landing.advanced.global.weather.humidity;
        }
      },
      temperatureValue:function(){
        if(this.isHotel){
          return this.hotel.advanced.global.weather.temperatureValue;
        }else{
          return this.landing.advanced.global.weather.temperatureValue;
        }
      },
      temperatureLow:function(){
        if(this.isHotel){
          return this.hotel.advanced.global.weather.temperatureLow;
        }else{
          return this.landing.advanced.global.weather.temperatureLow;
        }
      },
      temperatureHigh:function(){
        if(this.isHotel){
          return this.hotel.advanced.global.weather.temperatureHigh;
        }else{
          return this.landing.advanced.global.weather.temperatureHigh;
        }
      },
      windSpeed:function(){
        if(this.isHotel){
          return this.hotel.advanced.global.weather.windSpeed;
        }else{
          return this.landing.advanced.global.weather.windSpeed;
        }
      },

    },
  mounted() {
      console.log(this.landing.advanced.global.weather.week)
  },
  created() {
    this.updateTime();
    setInterval(this.updateTime, 1000);
  },
  data() {
        return {
          time: '',
            forecast: {
              cloudiness:0,
              windSpeed:0,
              humidity:0,
              temperatureValue:0,
              temperatureHigh:0,
              temperatureLow:0,
              description:'overcast clouds',
              weatherIcon:require('@/assets/icons/weather/cloud.svg'),
            }
        }
    },
  methods:{

    async toWeather(){
      if(this.isHotel){
        await this.$router.push(`/m/${this.$route.params.id}/weather`);
      }
    },
    updateTime() {
      if(this.isHotel){
        if (this.hotel.advanced.global.timezone) {
          let date = moment().tz(this.hotel.advanced.global.timezone.name);
          this.time = date.format('HH:mm');
        }
      }else{

        if (this.landing.advanced.global.timezone) {
          let date = moment().tz(this.landing.advanced.global.timezone.name);
          this.time = date.format('HH:mm');
        }
      }
    },
      convertDay(unix){
        return moment.unix(unix).format('ddd');
      },
    kelvinToCer(temp){
        return (temp).toFixed(0)
    },
    todayFormat(unix){
        return moment.unix(unix).format('ddd DD, GGGG')
    }
  }
}
</script>

<style scoped lang="scss">
.weatherHotel{
  width: 95%;
  padding-left: 15px;
}
main {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background:#fff;
    margin-top:5px;
    display: flex;
    padding-bottom:5px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;

    box-shadow: rgb(0 0 0 / 10%) 0px 3px 6px;
}
.weather{
  display: flex;
  justify-content: center;
  align-items: center;
}
/*.app--day {*/
/*    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('~@/assets/img/morning.jpg');*/
/*}*/

/*.app--night {*/
/*    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('~@/assets/img/night.jpg');*/
/*}*/

.login_or:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
}
@media screen and (min-width: 450px) {
    main {
        width: 100%;
        max-height: 180px;
        border-radius: 5px;
    }
}
.week{
  height:88%;
}
.weather-panel {
  background: #fff;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 6px;
  color: #999;
  overflow: hidden;
  position: relative;

small {
  color: inherit;
  font-size: 50%;
}
  ul.forecast{
    list-style: none;
  }
ul.forecast > li {
  border-top: 1px solid #eee;
}
.temperature {
  display: flex;
  align-items: center;
  justify-content: center;
& > span {
    font-size: 4em;
  }
}
}
</style>
