<template>
    <section :style="`color:${landing.advanced.global.weather.font_color}`">
            <img v-if="typeIcon === 0"  style="height: 50px;width: 50px;" :src="`https://openweathermap.org/img/wn/${icon}.png`" alt="">
            <i v-if="typeIcon === 1"  style="height: 40px;width: 40px;font-size:40px;margin-right: 10px;"  class="owf" :class="`owf-${iconWo}`"></i>
              <div class="temperature__value" :style="`color:${landing.advanced.global.weather.font_color}`">{{ (scaleSymbol === 'C')? value.toFixed(0) : fValue.toFixed(0) }}</div>
              <div class="temperature__right">
                  <div class="temperature__scale">
                      <a href="#"  :style="`color:${landing.advanced.global.weather.font_color}`" style="text-decoration: none;" @click.prevent="toggleTemperature" >&deg;{{ scaleSymbol }}</a>
                  </div>
        </div>
    </section>
</template>

<script>
import {mapState} from "vuex"
export default {
    name: 'Temperature',
    props: {
      typeIcon:{
        type: Number,
        required: true
      },
        value: {
            type: Number,
            required: true
        },
        high: {
            type: Number,
            required: true
        },
        low: {
            type: Number,
            required: true
        },
      description: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        required: true
      },
      iconWo: {
        type: Number,
        required: true
      },
    },
    data: function() {
        return {
            scale: 'Celcius'
        }
    },
    computed: {
      ...mapState(['landing']),
        scaleSymbol() {
            return this.scale.charAt(0);
        },

        fValue() {
            return this.toFahrenheit(this.value);
        },

        fHigh() {
            return this.toFahrenheit(this.high);
        },

        fLow() {
            return this.toFahrenheit(this.low);
        }
    },

    methods: {
        toFahrenheit(value) {
            return Math.floor((value * 1.8) + 32);
        },

        toggleTemperature() {
            (this.scale === 'Celcius')? this.scale = 'Fahrenheit' : this.scale = 'Celcius';
        }
    }
}
</script>

<style scoped>
section {
    display: flex;
    flex-direction: row;
    justify-content: center;
  align-items: center;
}

.temperature__value {
    font-size: 3em;
    color: rgba(0,0,0, 0.75);
}

.temperature__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.temperature__scale {
    padding-top: 5px;
    font-size: 2em;
    color: rgba(0,0,0, 0.75);
}

.temperature__high {
    padding-top: 5px;
}

</style>
